.grid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    gap: 1rem;
    margin: auto;
  }
  .empty-item{
    width: 30%;
    height: 10vmax;
    /* display: flex; */
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .grid-item img {
    width: 100%;
    height: auto;
    animation: fade 12s infinite;
  }

  .grid-item {
    max-width: 30%;
    /* border: 1px solid gray; */
    padding: 0.3vmax;
  }
  .round-item{
    width: 10vmax;
    height: 10vmax;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: var(--bi1);
    color: var(--textC2);
    font: 300 1.4vmax 'Roboto';
    text-align: center;
    padding: 1vmax;
  }
  .one-side-round{
    width: 10vmax;
    height: 10vmax;
    display: flex;
    border-radius: 50% 50% 50% 0;
    align-items: center;
    justify-content: center;
    background-color: var(--bi1);
    color: var(--textC2);
    font: 300 1.4vmax 'Roboto';
    text-align: center;
    padding: 1.5vmax;
  }
  
  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  