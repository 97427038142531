.event-main-Container{
    padding: 2rem 7vmax;
    background-image: linear-gradient(45deg, rgba(158, 158, 255, 0.201), rgba(255, 255, 255, 0.782));

}
.eventContainer{
    width: 100%;
    /* height: 150vh; */
    padding: 0 1vmax;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(255, 255, 255);
    /* background-image: linear-gradient(205deg, white, rgb(255, 249, 226) ); */    
    background-image: url('https://www.crizac.co.uk/catalog/view/theme/lexus_mayshop/new_design/img/resource/cream-banner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.eventHeader{
    width: 100%;
    padding: 0vmax 1vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid gray; */
    gap: 1rem;
}
.eventHeader > div > h1{
    font: 700 2.6vmax "Roboto";
    /* text-align: center; */
}
.eventHeader > div > p{
    font: 300 1.4vmax "Roboto";
    /* font-style: italic; */
}


.viewallBtn{
    padding: 0vmax 1.5vmax;
    width: 10vmax;
    height: 3vmax;
    color: white;
    background-color: var(--bi2);
    border: none;
    cursor: pointer;
    font: 500 18px roboto;
    transition: all 0.5s;
    border-radius: 0.2rem;
    box-shadow: 0 0.5px 5px gray;
}
.viewallBtn:hover{
    background-color: var(--bi2);
    box-shadow: 0 1px 5px gray;
}
.eventItemContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.eventBox{
    width: 100%;
    padding: 3vmax 1vmax    ;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
}
.dateBox {
    border-right: 1px solid gray;
    padding-right:  4vmax;
}
.dateBox > h1{
    font-size: 5vmax;
    color: var(--bi2);
    font-family: roboto;

}
.dateBox > h3{

    font-family: roboto;
    
}
.contentBox{
    min-width: 48%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 4vmax ;
    }
    .contentBox > h2{
        border-top: 1px solid rgb(212, 212, 212);
        border-bottom: 1px solid rgb(212, 212, 212);
        /* border-top: 1px solid gray; */
        padding: 1vmax 0;
        font: 600 1.6vmax 'Roboto';
    }
    .contentBox > p{
        padding-right: 2vmax;
        font: 300 1.1vmax 'Roboto';
    }
.imgBox{
    padding-left: 1vmax;
    width: 50%;
    height: 30vh;
}

/* video  */
.enventVideoBox{
    width: 100%;
    padding: 1vmax 0vmax;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.videoRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

}
.videoItem{
    width: 50%;
    height: 50vh;
    /* background-color: rgb(255, 170, 155); */
    /* box-shadow: 0 0 5px var(--bi2); */
    /* background-image: linear-gradient(white , var(--bi1)); */
    padding: 1vmax;
    border-radius: 0.4rem;
    transition: all 0.5s;
}
.videoItem:hover{
    background-image: linear-gradient( white,var(--bi1));

}
.videoItem> h2{
    /* font: 600 2vmax; */
    color: rgb(40, 40, 40);
    text-align: center;
    padding: 0.8vmax;
    font: 600 1.3vmax 'Roboto';
}
/* video end */

@media screen  and (max-width:600px){
    .eventContainer{
        width: 100%;
        padding: 0 2vmax;
        /* background-color: rgb(208, 208, 255); */
    }
    .viewallBtn{
        /* color: aqua; */
    padding: 0vmax 1.5vmax;
    width: 12vmax !important;
    height: 6vmax;

    font: 500 18px roboto;
}
.eventHeader > div > h1{
    font: 700 3.5vmax "Roboto";
}
.eventHeader > div > p{
    font: 300 1.8vmax "Roboto";
}
.eventBox{
    width: 100%;
    padding: 5vmax 0vmax    ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    gap: 1rem;
}
.dateBox {
    border-right: none;
    padding-right:  0vmax;
}

.contentBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 0vmax ;
    }
    .imgBox{
        width: 100%;
    }
        .contentBox > h2{
        font: 600 2.8vmax 'Roboto';
    }
    .contentBox > p{
        padding-right: 2vmax;
        font: 300 2vmax 'Roboto';
    }
    .videoRow{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    
    }
    .videoItem{
        width: 100%;
        height: 35vh;
        /* background-color: rgb(255, 170, 155); */
        /* background-image: linear-gradient(var(--bi1) , var(--bi2)); */
        padding: 1vmax;
        border-radius: 0.4rem;
        transition: all 0.5s;
    }
    .videoItem> h2{
        font-size: 2.5vmax;
        font-family: 'Roboto';
        color: rgb(40, 40, 40);
        text-align: center;
        padding: 0vmax;
        font-weight: 60;
    }
    /* .videoItem:nth-child(2) > video{
        height: 70%;
    } */
}