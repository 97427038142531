.CounterUIContainer{
    /* background-image: url(../../images/leftDotted.png) ; */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask2856%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient2857%26quot%3b)'%3e%3c/rect%3e%3cpath d='M1440 0L846.63 0L1440 107.25z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M846.63 0L1440 107.25L1440 318.69L538.39 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M538.39 0L1440 318.69L1440 378.28L431.33 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M431.3299999999999 0L1440 378.28L1440 487.47999999999996L223.06999999999994 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L176.81 560L0 494.76z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 494.76L176.81 560L744.1099999999999 560L0 220.28999999999996z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 220.28999999999996L744.1099999999999 560L804.3 560L0 146.21999999999997z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 146.21999999999997L804.3 560L1231.05 560L0 110.41999999999997z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask2856'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='100%25' y1='50%25' x2='0%25' y2='50%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient2857'%3e%3cstop stop-color='rgba(0%2c 124%2c 158%2c 0.15)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(155%2c 254%2c 255%2c 0.23)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");    background-size: cover;
    /* background-image: linear-gradient(to right, rgb(68, 199, 255) ,var(--bi1)); */
    /* height: 50vh; */
    padding: 2.5vmax 6vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.countHeading{
    padding: 0 4vmax;
    font: 600 1.8vmax 'Roboto';
    /* color: rgb(30, 30, 30) !important; */
    color: rgb(61, 61, 61) !important;
    /* padding-bottom: 1vmax; */
    /* border-bottom: 2px solid rgb(45, 45, 45); */
    /* border-bottom: 2px solid rgb(248, 248, 248); */

    color: rgb(184, 95, 0);
    text-align: center;
}
.counterBox{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2vmax 0;
}
.counterItem{
    width: 220px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 4vmax;
    font-family: 'roboto';
    /* box-shadow: 0 0 10px rgb(76, 146, 158); */
    /* background-color: white; */
    padding: 1vmax;
    border-radius: 50%;

    color: var(--bi2);
}
.counterItem > p{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.4vmax;
    color: rgb(59, 59, 59);
    font-family: 'roboto';
    text-align: center;
}
@media screen and (max-width:600px){
    .CounterUIContainer{
        /* background-image: url(../../images/ban-map.png) ; */
        background-repeat: no-repeat;
        background-position: left;
        /* background-color: #FFE1D4; */
        padding: 2.5vmax 2vmax;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .countHeading{
        padding: 0 0vmax;
        font: 600 2.4vmax 'Roboto';}
    .counterBox{
        width: 100%;
        display: flex;
        /* flex-direction: column; */
        flex-wrap:wrap ;
        justify-content: space-evenly;
        align-items: center;
        padding: 4vmax 0;
    }
    .counterItem{
        width: 50%;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        font-size: 4vmax;
        font-family: 'roboto';
        /* box-shadow: 0 0 10px rgb(76, 146, 158); */
        /* background-color: white; */
        padding: 0vmax 1vmax;
        border-radius: 50%;
    
        /* color: rgb(47, 47, 47); */
    }
    .counterItem > p{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 2.2vmax;
        color: rgb(59, 59, 59);
        font-family: 'roboto';
        text-align: center;
    }
}