

.swiper {
  width: 70%;
  height: 550px;
  position: absolute;
  left: 25%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide video {
  display: block;
  width: 100%;
  height: 490px;
}

@media screen and (max-width:600px){
  .swiper {
    width: 100%;
    height: 250px !important;
    position: relative;
    left: 1% !important;
    top: 0;
    margin-left: 0px;
    margin-top: 0px;

  }
  .swiper-slide video {
    display: block;
    width: 100%;
    height: 200px;
  }
  
}
