.downNav {
    position: absolute;
    /* border: 1px solid gray; */
    z-index: 99;
    width: 300px;
    padding: 0.5vmax;
    margin-top: 0.5vmax;
    margin-left: -6vmax;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0, 119, 209);
    /* border-radius: 0.4rem; */
    display: flex;
    flex-direction: column;
}

.downNavHide {
    display: none;
}

.downNav>a {
    text-decoration: none;
    font: 500 1vmax 'roboto';
    color: rgb(46, 46, 46);
    border-bottom: 1px solid rgb(223, 228, 255);
    padding: 1vmax 0
}

.downNav>a:last-child {
    border-bottom: none;
}

.downNav>a:hover {
    color: var(--bi2);
}

.topBorder {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    padding: 0.5vmax 6vmax;
    /* background-color: var(--bi2); */
    background-image: linear-gradient(to right, var(--bi2), var(--bi3));
    color: white;
    font: 300 1vmax 'roboto';
}

.topB1 {
    /* width: 50% */
}

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=textC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita&display=swap');

* {
    margin: 0;
    padding: 0;
    max-width: 100%;
    /* background-color: white; */
    box-sizing: border-box;
}

body {
    /* background: linear-gradient(var(--bgBody1),var(--bgBody2)); */
    background-color: var(--bgBody1);

}

:root {
    --bi1: #86c8ff;
    --bi2: #0487f3;

    --textC1: rgb(44, 44, 44);
    --textC2: white;
}

.fixed {
    margin-bottom: 5vmax;
    box-shadow: 0 0px 10px rgb(235, 235, 235);
    top: 0;
    z-index: 9999;
    position: fixed;
}

.HeaderContainer {
    width: 100vw;
    /* padding: 1rem; */
    /* background: linear-gradient(to right ,var(--bg1), var(--bg2)); */
    background-color: white;
    /* background-image: url('../../images/Rect\ Light.svg'); */
    display: flex;
    justify-content: space-between;
    padding: 0vmax 5vmax;
    /* position: absolute;
    z-index: 99; */



}

@keyframes logoAnimation {
    0% {
        /* position: absolute; */
        margin-left: -50px;
    }

    100% {
        /* position: absolute; */
        margin-left: 0;
    }
}

.sanwariyaLogo {
    animation: logoAnimation 1.2s 2s ease-in alternate;

}


.linkBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.activee {
    /* background-color: none !important; */
    /* border-bottom:  5px solid var(--hoverborder) !important ; */
    color: var(--bi2) !important;
    /* border-bottom: 2px solid var(--bi2); */

}

.iconBox {
    display: flex;
    flex-direction: column;

    position: absolute;
    display: block;
    top: 40%;
    right: 0;
    z-index: 99999;
    position: fixed;
    /* background-color: aqua; */
}

.iconItem {
    width: 50px;
    height: 50px;
    background-color: white;
    /* padding: 1vmax; */
    margin-right: 0.1vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: 0 1px 5px rgb(185, 185, 185);
    transition: all 1s;
    /* transform: rotate(360deg); */

}


.iconItem:hover {
    box-shadow: 0 1px 10px rgb(243, 81, 81) !important;
    transform: rotate(180deg);

}

.iconItem>svg {
    font-size: 24px;
    color: var(--bi2);
    transition: all 1s;

}

.linkBox>div,
.linkBox>a {
    text-decoration: none;
    font-family: Roboto;
    color: var(--textC1);
    /* border: 2px solid var(--border); */
    padding: 0.5rem 1rem;
    font-size: 16px;
    /* transition: 0.5s ease-in-out; */
    font-weight: 600;
    text-align: center;


}

.linkBox>a:hover {
    /* border-bottom: 2px solid var(--bi2); */
    color: var(--bi2);
    /* background-color: var(--bi1); */
}

.iconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.menu {
    /* width: 100%; */
    /* padding: 3vmax 0vmax; */
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.menu>svg {
    font-size: 4vmax;
}

.menuContainer {
    width: 100vw;
    max-width: 100%;
    height: 92vh;
    position: absolute;
    z-index: 999;
    /* top: 12vh; */
    background: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.menulogoBox {
    width: 100%;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    border-bottom: 1px solid gray;

}

.menulogoBox>p {
    font-size: 0.7rem;
    text-align: center;
    color: var(--textC1);
    font-weight: 600
}

.menulinkBox {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 1.5rem;
    margin: 3rem 0;

}

.menulinkBox>div {}

#mouseOverCountry{
    /* padding: 0 1.6vmax; */
    font: 600 1.05vmax 'Roboto';
    color: var(--textC1);
}
.menulinkBox>div>a {
    width: 100;
    text-decoration: none;
    font-family: Roboto;
    color: var(--textC1);
    /* border: 2px solid black; */
    padding: 0.5rem 0.2rem;
    font-size: 15px;
    transition: 0.5s ease-in-out;
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

.menuiconBox {
    padding: 0.2rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding: 1rem 0;
}

.menuiconBox>div>a>svg {
    color: var(--bi2);
    font-size: 24px;
}

.menulinkBox>div>a:hover {
    color: var(--hoverText);
    padding-left: 0.5rem;
}

.hide {
    transition: all 0.4s ease-in-out;
    top: -100%;
}

.show {
    transition: all 0.4s ease-in-out;
    top: 9.4vh;
}

.brandName {
    width: 100%;
    text-align: center;
    padding: 0.2rem 0;
    text-align: center;
    color: var(--textC1);
    font-size: 0.8rem;
}

/* .brandName>h3{
    width: 100%;
   
} */

@media screen and (max-width:952px) {
    .fixed {}

    .downNavMenu {
        position: relative;
        /* border: 1px solid gray; */
        z-index: 99;
        width: 100%;
        margin-top: 0.6vmax;
        margin-left: 1.5vmax;
        background-color: white;
        box-shadow: none;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        /* border: 1px solid rgb(219, 219, 219); */
    }

    .downNavHide {
        display: none;
    }
    #mouseOverCountry{
        padding: 0 1.6vmax;
        font: 600 2.2vmax 'Roboto';
        color: var(--textC1);
    }
    .downNavMenu>a {
        text-decoration: none;
        font: 500 2vmax 'roboto';
        color: rgb(46, 46, 46);
        border-bottom: 1px solid rgb(220, 220, 220);
        padding: 2vmax 0.5vmax;
        text-align: left;
    }

    .downNavMenu>a:hover {
        color: var(--bi2);
    }

    .topBorder {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5vmax 2vmax;
        background-color: var(--bi2);
        color: white;
        font: 300 1.4vmax 'roboto';
        display: none;
    }

    .HeaderContainer {
        padding: 0 1vmax;
    }

    .menu {
        /* width: 30%; */
        /* background-color: aquamarine; */
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .linkBox {
        display: none;
    }

    .brandName {
        width: 100%;
        text-align: center;
        color: var(--textC1);
        font-size: 0.6rem;
    }
}

@media screen and (min-width:952px) {
    .menuContainer {
        display: none;
    }
}