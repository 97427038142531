.pageHeader{
    width: 100%;
    background-position: center;
       background-repeat: no-repeat;
       background-size:cover ;
}
.pageHeader > div{
       width: 100%;
       /* height: 100%; */
       padding: 3vmax 5vmax;
       background-color: rgb(0, 0, 0 , 0.6);
}

.pageHeader >div> h1{
    font: 700 3vmax 'Roboto';
color: white;    
}
.pageHeader >div> h3{
    font: 300 1.5vmax 'Roboto';
    /* text-align: center; */   
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1vmax 0;
color: white;    

}
@media screen and (max-width:600px){
    .pageHeader >div> h1{
        font: 700 4.2vmax 'Roboto';
    }
    .pageHeader >div> h3{
        font: 300 2.5vmax 'Roboto';
        /* text-align: center; */   
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1vmax 0;
    }
    
}