.nopageContainer{
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nopageContainer > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nopageContainer > div h1{
  font: 800 3vmax 'Roboto';
}
.nopageContainer > div svg{
    font-size: 6vmax;
    color: gray;
}
@media screen and (max-width:600px){
    .nopageContainer > div h1{
        font: 800 5vmax 'Roboto';
      }
      .nopageContainer > div svg{
          font-size: 8vmax;
          color: gray;
      }
}