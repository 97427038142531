.itemAtag{
    text-decoration: none;
    /* padding: 2vmax !important; */
    
}

.slideMain{
    padding: 5vmax;
    background-color: white;
    color: black;
    /* background-image: url('../../images/canva-bg.png'); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
.iUrl1{
    transition: all 0.5s;

    background-image: url('../../images/uk.jpg');
}.iUrl2{
    background-image: url('../../images/usa.jpg');
}.iUrl3{
    background-image: url('../../images/autralia.jpg');
}
.item{
    min-width: 28vw !important;
    min-height: 40vh;
    margin: 0 1rem;
    height: 100%;
    transition: all 0.5s;
    color: black;
    background-color: rgb(255, 172, 127);
    /* background-image: url(https://martinoc.in/assets/img/uk.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 0 5px gray;
    border-radius: 0.4rem;
    align-items: end;
    overflow: hidden;
    cursor: pointer;
}
.item > a{
    /* width: 100%;
    height: 250%;
    display: flex;
    justify-content: flex-end; */
    text-decoration: none;
}
.item:hover{
    transition: all 0.5s ;
background-size: 120%;

.itemContent{
    height: 100%;
}
.hide{
    padding: 1vmax ;
    margin: 0.5vmax;
    display: block;
    border: none;
    background-color: transparent;
    color: white;
    border-radius: 0.4rem;
    cursor: pointer;
    font: 600 18px 'roboto';
    display: flex;
    align-items: center;
}
.hide >svg{
    font-size: 2vmax;
}
}
.itemContent{
    width: 100%;
    height: 25%;
    background-color: rgb(0, 0, 0,0.6);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: 700 2vmax "Roboto";
    transition: all 0.5s;
}
.hide{
    display: none;
}
.UniHeader{
    text-align: center;
    display: flex;
    justify-content: center ;
    /* background-color: tomato; */
}
.UniHeader > h1{
    text-align: center;
    width: 38%;
    font-family: Roboto;
    font-size: 1.8rem !important;
    color: var(--textC1);
}
.scrollLine{
    margin: 2vmax 0;
    margin-bottom: 2vmax;
}
@media screen  and (max-width:600px){
    .itemContent{
       
        font: 700 4vmax "Roboto";
    }
    .slideMain{        
        padding: 5vmax 0vmax;
        background-color: white;
        color: black;
    }
    .item{
        min-width: 90vw !important;
        /* padding: 1vmax; */
        margin: 0 0.7rem;
  
    }
    .UniHeader > h1{
        text-align: center;
        width: 100%;
        font-family: Roboto;
        font-size: 3vmax;
    }
    .scrollLine{
        margin: 1vmax 0 ;
        margin-bottom: 2vmax;
    }
}