.GalleryHeader{
    width: 100%;
    padding-top: 1vmax;
    /* background-color: white; */
    /* background-image: linear-gradient(45deg, rgba(158, 158, 255, 0.201), rgba(255, 255, 255, 0.782)); */

}
.slideGallary{
    /* background-color: white; */
    /* background-image: url(../../images/canva-bg.png); */
    /* background-position: center; */
    padding-bottom: 8vmax;
}
.GalleryHeader > h1{
    text-align: center;
    font: 800 3vmax 'Roboto';
}
.galleryContainer{
    width: 100%;
    /* background-color: white; */
    padding: 2vmax 10vmax;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.galleryContainer .frame{
    display: flex;
    /* justify-content: space-between; */
    gap: 1%;
}
.frame > div{
    width: 33%;
    height: 40vh;
    transition: all 0.5s;
    /* border-radius: 0.4rem; */
    /* background-color: white; */
    box-shadow: 0px 0px 10px rgb(148, 187, 255);
    background-color: rgb(148, 187, 255);
    overflow: hidden;

}
.frame > div::-webkit-scrollbar{
    width: 5px;
    height: 5px;
}
.frame > div::-webkit-scrollbar-thumb{
    background-color: grey;
}
.frame > div:hover{
    /* transform: scale(1.25); */
    box-shadow: 1px 1px 10px gray;
}
.frame > div > img{
    background-color: aquamarine;

    /* padding: 1vmax; */
    width: 100%;
    height: 100%;
}

.galleryAllImages{
    padding: 7vmax;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    flex-wrap: wrap;
}
.g-image{
    width: 32%;
    height: 40vh;
    box-shadow: 0px 0px 10px rgb(148, 187, 255);
    background-color: rgb(148, 187, 255);
    overflow: hidden;

    >img{
        width: 100%;
        height: 100%;
    }
}
.one-image{
    height: 65vh;
    overflow: hidden;
    margin: 2vmax 1vmax;
    background-image: url(https://res.cloudinary.com/dzgc1ajz7/image/upload/v1732091164/website-imgs/464203226_1144347687055563_7053282105347079061_n_bqzkpo.jpg);
    background-position: center;
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.238);
}
@media screen  and (max-width:600px){
.GalleryHeader > h1{
    text-align: center;
    font: 800 4vmax 'Roboto';
    margin-bottom: -3vmax;
}
.galleryContainer{
    width: 100%;
    background-color: white;
    padding: 0 1vmax;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.galleryContainer .frame{
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    gap: 1%;
}
.frame > div{
    width: 99%;
    height: auto;
    transition: all 0.5s;
    /* border-radius: 0.4rem; */
    overflow: hidden;
    margin: 1vmax 0;
}


.frame > div:hover{
    /* transform: scale(1.05); */
    box-shadow: 1px 1px 10px gray;
}
.galleryAllImages{
    padding: 5vmax 1vmax;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.g-image{
    width: 47%;
    height: auto;
    box-shadow: 0px 0px 10px rgb(148, 187, 255);
    background-color: rgb(148, 187, 255);
    overflow: hidden;

    >img{
        width: 100%;
        height: 100%;
    }
}
.one-image{
    height: 30vh;
    overflow: hidden;
    margin: 2vmax 1vmax;
    background-image: url(https://res.cloudinary.com/dzgc1ajz7/image/upload/v1732091164/website-imgs/464203226_1144347687055563_7053282105347079061_n_bqzkpo.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.238);
}

}