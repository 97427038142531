.backBanner {
    width: 100%;
    height: 60vh;
    background-image: url('../../images/study-abroad-banner1.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -999;
    bottom: 2px;
    position: fixed;
}

.backContectBox {
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.6);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    color: white;
}

.subscribeBox {
    width: 100%;
    height: 100%;
    /* background-color: white; */
    padding-top: 2vmax;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: white;
    background-image: url('../../images/san-foot1.png');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}

.subscribeBox>div {
    width: 60%;
    height: 36vh;
    background-image: url(https://eduma.thimpress.com/demo-main/wp-content/themes/eduma/images/bg-newletter.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

}

.subscribeBox>div>input {
    width: 32%;
    padding: 1vmax;
    outline: none;
    border: 0.1px solid rgb(202, 202, 202);
}

.submitBtn {
    width: 10% !important;
    background-color: var(--bi2);
    cursor: pointer;
    color: white;


}

.getBtn {
    padding: 1vmax 3vmax;
    color: white;
    background-color: transparent;
    border: 2px solid rgb(255, 255, 255);
    cursor: pointer;
}

.footerContainer {
    max-width: 100%;
    width: 100vw;
    /* background-color: var(--bi2); */
    /* background-image: linear-gradient(var(--bi1), var(--bi2)); */
    color: var(--textC2);
    /* border-top:5px solid black; */
    /* margin-bottom: 60vh; */
}

.topBox {
    /* padding-top: -2rem; */
    /* height: 44vh; */
    padding: 3vmax 7rem;
    display: flex;
    justify-content: space-between;
    align-items: top;
    /* flex-wrap: wrap ; */
    /* border: 1px solid gray; */
}

.downBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(216, 216, 216);
    padding: 2vmax 7rem;

}

.downLeftBox {
    /* border: 1px solid gray; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 0.1rem;


}

.downLeftBox>p {
    color: var(--textC1);
    font-family: 'Times New Roman', Times, serif;
    font-size: 1rem;
    text-align: center;
    padding: 0;
}

.downLeftBox>p>span {
    color: var(--textC1);
}

.downRightBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.downRightBox>a {
    text-decoration: none;
    color: white;
}

.downRightBox>a>i {
    border: 1px solid wheat;
    padding: 0.8rem;
    background-color: var(--bg3);
    box-shadow: 2px 2px 4px var(--bg1);
    transition: 0.5s ease-in;

}

.downRightBox>a>i:hover {
    background-color: white;
    color: black;

}

.box1,
.box3,
.box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
}

.topBox .box1 {
    width: 45%;
}

.box1>div>p {
    font-family: "Roboto";
    /* padding: 0.3vmax 0; */
}

.box1>p {
    padding-right: 1.5vmax;

}

.topBox .box2 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.6rem;
}

.topBox .box3 {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    /* padding-top: 1rem; */
}

.Box2heading,
.Box1heading {
    width: 80%;
    font: 600 1rem "Roboto";
    color: var(--bi2);
    margin-bottom: 0rem;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.atag {
    text-decoration: none;
    color: var(--textC1);
    font: 500 1rem "Roboto";
    transition: 0.5s ease-in-out;
    padding: 0.4rem 0.2rem;
    margin: 0rem 0;

}

.atag:hover {
    /* color: aquamarine; */
    padding-left: 0.6rem;
}

.colorMidDark {
    color: var(--textC1);
}



.box3 .mapBox {
    width: 100%;
    /* height: 14vmax; */
    padding: 0.5rem;
    /* border: 2px solid gray; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}

.box3 .mapBox>a>svg {
    /* font: 500 1.8vmax ''; */
    font-size: 3vmax;
    color: white;
    transition: all 0.5s;
    border: 2px solid var(--bi2);
    padding: 0.6vmax;
    border-radius: 0.4rem;
}

.box3 .mapBox>a>svg:hover {
    border: 2px solid rgb(44, 44, 44);
    color: rgb(42, 42, 42);
}

.box3 .logoBox {
    padding: 2rem;
    background-image: linear-gradient(rgba(255, 255, 255, 0.222), rgba(255, 255, 255, 0.49));
    border-radius: 1rem;
    text-align: center;
}

.box3>.logoBox>p {
    font: 600 1rem "Lobster";
    color: var(--textC1);
}

.logoBox>img {
    /* -webkit-background-origin: none; */
}

@media screen and (max-width:952px) {
    .footerContainer {
        max-width: 100%;
        width: 100vw;
        /* height: 50vh; */
        /* padding: 2rem 1rem; */
        /* margin-top: 1rem; */
        bottom: 0;
        color: var(--textC2);
    }

    .Box2heading,
    .Box1heading {
        width: 80%;
        font: 600 1.1rem "Roboto";
        /* color: black; */
        margin-bottom: 0rem;
    }

    .subscribeBox {
        width: 100%;
        padding: 0 2vmax;
        background-color: white;
        padding-top: 2vmax;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .subscribeBox>div {
        width: 100%;
        height: 36vh;
        background-image: url(https://eduma.thimpress.com/demo-main/wp-content/themes/eduma/images/bg-newletter.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

    }

    .subscribeBox>div>input {
        width: 80%;
        padding: 1vmax;
        outline: none;
    }

    .submitBtn {
        width: 30% !important;
        background-color: var(--bi2);
        cursor: pointer;
        color: white;

    }

    .topBox {
        /* height: 100vh; */
        padding: 3vmax 2vmax;

        display: flex;
        flex-direction: column;
        /* justify-content: space-between;
    align-items: flex-end; */

    }

    .downBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        gap: 1rem;
    }

    .topBox .box1 {
        width: 100%;
    }

    .topBox .box2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.6rem;
        margin: 1.5rem 0;
    }

    .topBox .box3 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: center;
        padding-top: 0;
    }

    .box3 .mapBox {
        width: 100%;
        /* height: 25vmax; */
        padding: 0.5rem;
        /* border: 2px solid gray; */

    }

    .box3 .mapBox>a>svg {
        /* font: 500 1.8vmax ''; */
        font-size: 5vmax;
        color: white;
        transition: all 0.5s;
    }
}

@media screen and (max-width:600px) {}