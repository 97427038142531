.testimonialContainer {
  /* padding: 1 6vmax; */
  /* height: 100vh; */
  /* background-color: rgb(220, 215, 215); */
  overflow: hidden;
  padding: 2vmax 0;

}

/* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%; 
} */


/* body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 80%;
  /* padding-top: 50px; */
  /* padding-bottom: 50px; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 420px;
}

.swiper-slide>video {
  display: block;
  width: 100%;
  height: 100%;
}

.mySwiper {
  margin-top: 4rem !important;
  position: relative !important;
  /* bottom: -100rem !important; */
}

/* ------------------------------------------ manual swiper */
.testimonialForCelBox {
  width: 80%;
  height: 100%;
  margin: 2vmax auto;
  display: flex;
  overflow: auto;
  align-items: center;
  gap: 0.7rem;
  padding: 1vmax 0;
}

.textItem {
  min-width: 19%;
  height: 100%;

  border-radius: 0.4rem !important;
  overflow: hidden;

  /* width: 100%;

  height: 100%; */
  /* background-color: antiquewhite; */
  background-image: url(../../images/video-background3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0.5vmax;


  >video {
    height: 100%;
    border-radius: 0.4rem !important;
    overflow: hidden;
    cursor: pointer;

  }
}

.testimonialForCelBox::-webkit-scrollbar {

  height: 0px;
  background-color: white;
  border-radius: 2rem;
}

.testimonialForCelBox::-webkit-scrollbar-thumb {

  background-color: rgb(218, 245, 255);
  border-radius: 2rem;

}

.link-Box {
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;

  >a {
    text-decoration: none;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    color: white !important;
  }
}

.heading {
  text-align: center;
  color: var(--bi2);
}

.bgColor {
  /* background-color: var(--bi2); */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask2856%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient2857%26quot%3b)'%3e%3c/rect%3e%3cpath d='M1440 0L846.63 0L1440 107.25z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M846.63 0L1440 107.25L1440 318.69L538.39 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M538.39 0L1440 318.69L1440 378.28L431.33 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M431.3299999999999 0L1440 378.28L1440 487.47999999999996L223.06999999999994 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L176.81 560L0 494.76z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 494.76L176.81 560L744.1099999999999 560L0 220.28999999999996z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 220.28999999999996L744.1099999999999 560L804.3 560L0 146.21999999999997z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 146.21999999999997L804.3 560L1231.05 560L0 110.41999999999997z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask2856'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='100%25' y1='50%25' x2='0%25' y2='50%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient2857'%3e%3cstop stop-color='rgba(0%2c 124%2c 158%2c 0.15)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(155%2c 254%2c 255%2c 0.23)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  background-size: cover;

  padding: 4vmax 0;
}

.textItemRound {

  min-width: 24%;

  height: 100%;
  /* background-color: antiquewhite; */
  /* background-image: url(../../images/video-background3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  padding: 0.5vmax;
  display: flex;
  align-items: center;


  >video {
    height: 100%;
    width: 100%;
    border-radius: 0.8rem;
    /* overflow: hidden; */
  }
}

@media screen and (max-width:600px) {
  .testimonialContainer {
    /* padding: 1 6vmax; */
    /* height: 100vh; */
    /* background-color: rgb(220, 215, 215); */
    overflow: hidden;

  }

  .swiper {
    width: 100%;
    height: 400px;
    /* padding-top: 50px; */
    /* padding-bottom: 50px; */
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 75%;
    height: 320px;
  }

  .swiper-slide>video {
    display: block;
    width: 100%;
    height: 320px;
  }

  .mySwiper {
    margin-top: 4rem !important;
    position: relative !important;
    /* bottom: -100rem !important; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper {
    width: 100% !important;
    left: 30% !important;
  }

  /* -------------------------------------------------------- */
  .testimonialForCelBox {
    width: 80%;
    margin: 2vmax auto;
    display: flex;
    overflow: auto;
    gap: 1rem;
    padding: 1vmax 0;
  }

  .textItem {
    min-width: 90%;
  }

  .heading {
    text-align: center;
    font: 600 1.8vmax 'Roboto';
    color: var(--bi2);
  }

  .link-Box {
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;

    >a {
      text-decoration: none;
      width: 24%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      color: white !important;
    }
  }

}