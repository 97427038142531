
.slideItem{
    width: 100%;
    height: 100%;
    /* background-color: transparent; */
    /* background-color: gray; */

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
}
.slideItem > video{
    /* background-color: transparent !important; */
}
.awssld {
    width: 100%;
    height: 80vh;
    background-color: gray;

}
.url1{

    background-image: url(https://martinoc.in/assets/img/banner4.png);
}
.url2{
  
    background-image: url(https://martinoc.in/assets/img/banner5.jpg);
}
.url3{

    background-image: url('../../images/home/hom\ \(1\).jpeg');
}
.url4{
  
    background-image: url('../../images/home/hom\ \(2\).jpeg');
}
.url5{

    background-image: url('../../images/home/hom\ \(1\).jpg');
}
.url6{

    background-image: url('../../images/home/sanwariya-hp.jpg');
}
.url7{

    background-image: url('https://res.cloudinary.com/dwevw3pnz/image/upload/v1695447485/sanwariyaVideos/SQP06340_1_-min_owhjge.jpg');
}
.contactMain{
    width: 100%;
    padding: 0 5vmax;
    margin: 1vmax 0;
    background-color: white;
}
.contactContainer{
    width: 100%;
    /* padding: 5vmax 0; */
    height: 40vh;
    background-image: url(../../images/study-girl.webp);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    overflow: hidden;
}
.contactContainer > div{
    width: 100%;
    height: 100%;  
    background-color: rgb(0, 0, 0, 0.6) ;
    padding: 2vmax 5vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* color: wheat; */
    gap: 1rem;
}
.contactContainer > div > h1{
    font: 600 2vmax 'Roboto';
    color: white;
    text-align: center;
}
.queryBtn{
    font: 600 1vmax 'Roboto';
    color: white;
    padding: 0.8vmax 2vmax;
    background-color: var(--bi2);
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
}
.queryBtn:hover{
    background-color: var(--bi1);
}
/*  */
 /* secsion 4 People says */
 .reviewContainer > .awssld{
    height: 43vh;
    border: none;

 }
 .reviewContainer{
    /* margin: 2vmax 0; */
    padding: 5vmax 0vmax;
    background-color: white;
    text-align: center;
    
 }
 .allreviewBox{
    width: 100%;
    margin-top: 5vmax;
    /* background-color: aquamarine; */

 }
 .checkOutReviewBtn{
    padding: 1vmax;
    text-decoration: none;
    text-align: center;
    width: 100%;
    background-color: var(--bi2);
    font: 600 18px 'Roboto';
    color: white;
    border-radius: 0.4rem;
    transition: all 0.5s;
    /* margin: 0 auto; */
}
.checkOutReviewBtn:hover{
    background-color: rgb(255, 162, 146);
}
.reviewHeader{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vmax 0;
    background-color: var(--bi1);

}
.reviewHeader>p{
    font-family: 'Roboto';
    text-align: center;

}
.reviewHeader>h2{
    /* width: 100%; */
    font-size: 35px;
    font-family: 'Roboto';
    text-align: center;
    padding: 1vmax;
    /* border-bottom: 2px solid var(--bi2); */
}
    .reviewItem{
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 0 24vmax;
        margin: 3vmax 0;
    }
    .reviewItem > p{
        text-align: center;
        font-family: 'Roboto';
        font-size: large;
    }
    .reviewItem > h3{
        text-align: center;
        font-family: 'Roboto';
        font-size: 24px;
    }
    .profileImg{
        width: 100px;
        height: 100px;
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        text-align: center;
        border: 2px solid rgb(114, 114, 114)  ;
        padding: 0.2vmax;
    }
.reviewItem > svg{
    font-size: 30px;
}
.eventUi{
    padding: 0 7vmax;
    padding-bottom: 2vmax;
}
  /* secsion 4 People says end*/
@media screen and (max-width:600px){
    .reviewContainer > .awssld{
        height: 68vh;
        border: none;
    
     }
    .awssld {
        width: 100%;
        height: 36vh;
    }
    .contactContainer{

        height: 30vh;
     
    }
    .contactMain{
        width: 100%;
        padding: 0 1vmax;
        background-color: white;
    }
    .queryBtn{
        font: 600 2vmax 'Roboto';
        color: white;
        padding: 0.8vmax 2vmax;
        background-color: var(--bi2);
        border: none;
        border-radius: 0.2rem;
        cursor: pointer;
    }
    .contactContainer > div{
    width: 100%;
    gap: 1rem;
}
.contactContainer > div > button{
    font: 600 1.8vmax 'Roboto';
    color: white;
    padding: 0.8vmax 2vmax;
    background-color: var(--bi2);
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
}
.contactBox,
.conBox1{
    width: 100%;
    padding: 0;

}
.conBox1:first-child > p{
    color: white;
    font-size: large;
    text-align: center;
}
.conBox1:first-child > h1{
    color: white;
    text-align: center;

    font-size: 6vmax;
}


.contactBox > div{
    display: flex;
    flex-direction: column;
    padding: 4vmax;
    gap: 1rem;
}
.contactBox > h3{
    background-color: var(--bi2);
    padding: 4vmax 1.5vmax;
    border-radius: 0 0 50% 50% ;
}
.contactBox > div>input{
    width: 100%;
    padding: 1.2vmax;
    outline: none;
}
.contactBox > div>input:last-child{
    background-color: var(--bi2);
    color: white;
    border: none;
}
 /* secsion 4 People says */
  .reviewContainer{
    /* margin: 2vmax 0; */
    /* padding: 5vmax 0vmax; */
    padding-bottom:  5vmax;
    background-color: white;
    border: none;

 }
 .allreviewBox{
    width: 100%;
    margin-top: 8vmax;
    /* background-color: aquamarine; */

 }
 .reviewItem{
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 0 3vmax;
    }
    .eventUi{
        padding: 0 0vmax;
    }
 /* secsion 4 People says end */
    
}