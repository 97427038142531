.mainBox{
    /* background-color: white; */
    /* background-image: linear-gradient(205deg,  rgb(255, 249, 226),white ); */
    padding: 0vmax 5vmax   ;
    margin: 1vmax 0;

}
.mainBox > div{
    display: flex;
    justify-content: center;
}
.CourseHeader{
    width: 100vw ;
    padding: 2vmax 1vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(255, 229, 224); */
    position: relative;
    z-index: 99999;
    /* margin-bottom: 2vmax; */
}
.CourseHeader > h1{
    font: 700 3vmax 'Roboto';
}
.courseContainer{
    /* max-width: 100vw;     */
    width: 100%;
    /* width:min-content; */
    /* padding-left: 20%; */

    /* border: 1px solid black; */
    /* padding: 0 3vmax; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    left: 0;
    overflow: auto;
    /* overflow:scroll; */
}
/* .courseContainer::-webkit-scrollbar{
    width: 0;
    /* left: 0; 
} */
.courseItem{
    /* min-width: 20vw; */
    width: 25vw;
    height:64vh;
    border: 0.5px solid rgb(230, 230, 230);
    display: flex;
    margin: 0 2vmax;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid rgb(230, 230, 230);
    box-shadow: 0 0px 2px gray;
    border-radius: 0.2rem;
    background-color: white;

    border-radius: 0.4rem;
    overflow: hidden;
    /* padding-bottom: 1.5vmax; */


}
.courseItem > div {
    width: 60px ;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    margin-top: -8%;
    padding: 0.5vmax;
    
}
.courseItem > div > img{
    border-radius: 50%;

}
.courseItem > img{
    width: 100%;
    height: 50%;
}
.courseItem > h3{
    padding: 0.5vmax 1vmax;
    text-align: center;
    font: 700 1.6vmax  'Roboto';
}
.courseItem > p{
    padding: 0.5vmax 1vmax;
    text-align: center;
    font: 300 1.2vmax  'Roboto';

}
.readBtn{
    width: 100%;
    padding: 1vmax 0;
    border: none;
    background-color: tomato;
    margin-top: 1vmax;
    font: 600 1.2vmax 'roboto';
    color: white;
    cursor: pointer;
    transition: all 0.5s;
    
}
.readBtn:hover{
    background-color: rgb(251, 148, 130);
}
.courseItem > button:hover{
    background-color: rgb(255, 38, 0);
    color: rgb(255, 255, 255);
}
.scrollLine{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vmax 0;
}
.scrollLine > div{
    width: 14%;
    height: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bi1);
    border-radius: 1rem;
}
.scrollLine > div > div{
    width:37%;
    height: 7px;
    background-color: var(--bi2);
    border-radius: 0.2rem;
}
@media screen  and (max-width:600px){
    .mainBox{
    background-color: white;
    padding: 5vmax 1vmax   ;
}
.CourseHeader{
    width: 100%;
    padding: 4vmax 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CourseHeader > h1{
    font: 700 3vmax 'roboto';
}
.viewallBtn{
    width: 30%;
    height: 4vmax;
    font-size: 2vmax;   
    font: 400 1.8vmax 'Roboto';
    border-radius: 0.2rem;
}
.courseItem{
    min-width: 80vw;
    height: 55vh;
    /* background-color: aquamarine; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid rgb(230, 230, 230);

}

.courseItem > img{
    width: 100%;
    height: 50%;
}
.courseItem > h3{
    font: 600 3.4vmax 'roboto';
    padding: 0.2vmax 1vmax;
}
.courseItem > p{
    text-align: center;
    font: 400 2.3vmax 'roboto';

   padding: 1vmax 1vmax;
}
.readBtn{
    width: 100%;
    padding: 1.5vmax 0;
    border: none;
    background-color: tomato;
    margin-top: 1vmax;
    font: 600 2vmax 'roboto';
    color: white;
    cursor: pointer;
    transition: all 0.5s;
    
}
.scrollLine{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 6vmax 0 !important; */
}
}