.ourSerContainer{
    width: 100%;
    /* margin: 1vmax 0; */
    padding: 6vmax 0vmax;
    background-color: white;
    /* background-image: url('../../images//canva-bg.png'); */
    background-image: url('https://www.crizac.co.uk/catalog/view/theme/lexus_mayshop/new_design/img/resource/cream-banner.png');

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.OsHeader{
    /* width: 30%; */
    text-align: center;
    padding: 3vmax 3vmax;
    color: rgb(65, 65, 65);
    /* background-color: rgb(255, 240, 238 ,0.5); */
    font-family: 'Roboto';
    margin:  0 auto;
}
.Os-Row{
    width: 100%;
    /* background-color: aliceblue; */
    padding: 0 10vmax;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0;
}
.Os-Row > div{
    display: flex;
    width: 100%;
    gap: 2rem;
    justify-content: space-evenly  ;
}
.OsItem{
    width: 44%;
    height: 25vh;
    padding: 2vmax 1vmax;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    /* background-color: rgb(255, 236, 233); */
    border-bottom: 4px solid var(--bi1);
    box-shadow: 15px 15px 15px grey;
    transition: all 0.5s;
}
.OsItem:hover{
    background-color: rgb(255, 244, 244);
    position: relative;
    margin-top   :-0.5vmax ;
    /* transform: scale(1.2); */

}

.OsItem  svg:hover{
    transition: all 1s;
   transform: rotate(360deg);
}
.OsItem > h3{
    font: 600 1.6vmax 'Roboto';
    color: rgb(51, 51, 51);
    text-align: center;
}
.OsItem > svg{
    font-size: 2.8vmax;
    color: var(--bi1) !important;
}
@media screen and (max-width:600px  ){
    .Os-Row{
        width: 100%;
        /* background-color: aliceblue; */
        padding: 0 1vmax;
        display: flex;
        /* flex-direction: c; */
        /* justify-content: space-between; */
        gap: 0.3rem;
        margin: 1rem 0;
    }
    .Os-Row > div{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;
        justify-content: center ;
    }
    .OsItem{
        width: 96%;
        height: 20vh;
        padding: 4vmax 2vmax;
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    .OsItem > h3{
        font: 600 2.2vmax 'Roboto';
        text-align: center;
    }
    .OsItem > svg{
        font-size: 3.5vmax;
        color: tomato;
    }
}